
@import 'bootstrap/scss/bootstrap';


body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
}

body:not(.dark-mode-preloading) {
  transition: all 0.3s linear;
}

body.dark-mode {
  background-image: linear-gradient(147deg, #000000 0%, #2c3e50 74%);
  color: #b1cbd8;
}

body.light-mode {
  background-image: linear-gradient(315deg, #b8c6db 0%, #f5f7fa 74%);

  color: #2d3436;
}




::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background-color: #6750A4;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: #b1cbd8;
}

html{
  height: 100%;
  scroll-behavior: smooth;
  width: 100%;   
}

@media only screen
and (min-device-width: 320px)
and (max-device-width: 568px) {
html{
  height: 100%;
  scroll-behavior: smooth;
  width: 100%;   
}
}


p,li{
  font-size: 1.1em;
  font-weight: 500;
  cursor: default;
}

em,i{
  color: var(--em);
}

li{
  
  //color: white;
  cursor: pointer;
}

li:hover {
  color: #737594;
}

span{
  
}



h1,h2,h3,h4,h5,h6{
  cursor: default;
  font-weight: 900;
  
}





a {  
  //color: var(--link);
  text-decoration: none;
  
  
}

a:hover {
  color: #737594;
}



//Divider
.custom-shape-divider-bottom-1667156080 {
  //position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  transform: rotate(180deg);
}

.custom-shape-divider-bottom-1667156080 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 29px;
}

.custom-shape-divider-bottom-1667156080 .shape-fill {
  fill: #6750A4;
}



.custom-shape-divider-top-1667156508 {
  //position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1667156508 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 29px;
}

.custom-shape-divider-top-1667156508 .shape-fill {
  fill: #6750A4
}

